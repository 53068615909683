<script setup>
import { mdiCog } from '@mdi/js'
import { useMainStore } from '~/stores/main'
import {computed, onMounted, ref} from 'vue'
import Icon from '@/components/Admin/Icon.vue'

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  headerIcon: {
    type: String,
    default: null
  },
  rounded: {
    type: String,
    default: 'md:rounded'
  },
  hasTable: Boolean,
  empty: Boolean,
  form: Boolean,
  hoverable: Boolean,
  modal: Boolean,
  to: {
    type: String,
    default: null
  },
  toLabel: {
    type: String,
    default: null
  },
  status: {
    type: String,
    default: null
  },
  showHide: Boolean,
})

const emit = defineEmits(['header-icon-click', 'submit'])

const is = computed(() => props.form ? 'form' : 'div')

const mainStore = useMainStore()

const lightBorderStyle = computed(() => mainStore.lightBorderStyle)

const componentClass = computed(() => {
  const base = [
    props.rounded,
    lightBorderStyle.value,
    props.modal ? 'dark:bg-gray-900' : 'dark:bg-gray-900/70'
  ]

  if (props.hoverable) {
    base.push('hover:shadow-lg transition-shadow duration-500')
  }

  return base
})

const computedHeaderIcon = computed(() => props.headerIcon ?? mdiCog)

const hideShow = ref(true);

const headerIconClick = () => {
  emit('header-icon-click')
}

const submit = e => {
  emit('submit', e)
}

onMounted(()=>{
  if(props.showHide){
    hideShow.value = false;
  }
})
</script>

<template>
  <component
    :is="is"
    :class="componentClass"
    class="bg-white border dark:border-gray-800"
    @submit="submit"
  >
    <header
      v-if="title"
      :class="lightBorderStyle"
      class="flex items-stretch border-b dark:border-gray-800"
    >
      <p
        class="flex items-center py-3 grow font-bold"
        :class="[ icon ? 'px-4' : 'px-6' ]"
      >
        <icon
          v-if="icon"
          :path="icon"
          class="mr-3"
        />
        {{ title }}
      </p>
      <div v-if="to" class="mb-6 last:mb-0 flex  justify-between py-3 px-3">
        <admin-jb-button
            :to="to"
            :label="toLabel"
            color="info"
            small
        />
      </div>
      <div v-if="status" class="mb-6 last:mb-0 flex  justify-between py-3 px-3">
        <p class="flex items-center py-3 grow font-bold px-6 capitalize"
        :class="status==='declined'? 'text-red-600':''"
        >
          {{ status }}
        </p>
      </div>
      <div v-if="showHide" class="mb-6 last:mb-0 flex  justify-between py-3 px-3">
        <admin-jb-button
            @click="hideShow = !hideShow"
            :label="hideShow? 'Hide':'Show'"
            color="info"
            small
        />
      </div>
    </header>
    <div
      :class="{'py-2 px-6 max-w-screen-lg':!hasTable}"
      v-show="hideShow"
    >
      <slot />
    </div>
  </component>
</template>
